@import "config";

.accountInfoWindow {
  width: 24rem;
  .row {
    + .row {
      margin-top: $contentDefaultIndent;
    }
  }
  .label {
    margin-right: 0.5rem;
    font-weight: 500;
  }
}
