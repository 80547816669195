@import "config";

.accountsPage {
  .errorAlert {
    margin-top: $contentDefaultIndent;
  }
  .institutions {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
  .noDataContent {
    max-width: 16rem;
    margin: auto;
    text-align: center;
    .icon {
      svg {
        margin-bottom: $contentDefaultIndent;
        opacity: 0.2;
        font-size: 6rem;
      }
    }
    h4 {
      color: $uiNeutralColor;
    }
  }
}
